<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Case Law Summarizer" subtitle="">
      <button
        v-if="tableLoading || tableData.length"
        class="btn-primary"
        @click="showAttachModal"
      >
        <span class="material-symbols-rounded">add</span>
        Add new case
      </button>
    </BreadCrumb>
    <!-- modal -->
    <b-modal id="attachfile" hide-footer hide-header size="lg">
      <!-- Modal header with title and close icon -->
      <div class="d-flex justify-content-between mt-3">
        <div>
          <h5>Upload Files</h5>
        </div>
        <div>
          <span
            class="material-symbols-rounded modal-icon"
            @click="hideAttachModal"
            >close</span
          >
        </div>
      </div>

      <!-- File Input and Processing -->
      <div>
        <FileInput style="flex-grow: 1;" :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']" :single="true"
          @input-files="$set(files, 0, $event)" />

        <FileProcess style="width: 100%; max-height: 200px; overflow-y: auto;"
          :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']" :files="files[0]"
          @output-files="$set(files, 1, $event)" />

        <!-- File Name Input -->
        <div class="mt-4">
          <label for="fileName" class="pt mb-2">File Name</label>
          <input
            type="text"
            v-model="selectedFileName"
            id="fileName"
            class="form-control custom_file"
            placeholder="Please rename your file"
          />
        </div>
      </div>

      <!-- Modal Footer with Upload Button -->
      <footer class="mt-3">
        <div class="d-flex justify-content-end">
          <div class="upload" @click="handleFileUpload">
            <span class="material-symbols-rounded icon">upload</span>
            Upload
          </div>
        </div>
      </footer>
    </b-modal>
    <TableList
      class="table-margin"
      v-if="tableLoading || tableData.length > 0 || $route.query.file_name"
      :percentageColumnIndex="4"
      :show-buttons="true"
      :load-data="loadData"
      :loading="tableLoading"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actions="tableHandler"
      @sort-data="sortData"
      :search="tableSearchConfig"
      @send-query="sendQuery"
      @row-click="navigateToCampaign"
    />

    <div v-else class="empty-placeholder">
      <span class="min-margin"></span>
      <img src="@/assets/misc/empty.svg" alt="No Content" />
      <p class="m-0">Welcome to the Case Law Summarizer!</p>
      <p>No summaries available yet — upload a case to get started.</p>
      <button
        class="btn-primary"
        @click="showAttachModal"
      >
        <span class="material-symbols-rounded">add</span>
        Add new case
      </button>
    </div>

    <!-- Delete Modal -->
    <b-modal id="delete-modal" hide-header hide-footer centered>
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p style="text-align: center;">
          <b>Are you sure you want to delete this campaign?</b><br />
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BreadCrumb from "../../components/misc/BreadCrumb.vue";
import TableList from "../../components/misc/TableList.vue";
import casesummarizer from "../../store/CaseSummarizer.js";
import FileInput from "../../components/input/FileInput.vue";
import FileProcess from "../../components/input/FileProcess.vue";
// import {createFormData} from "../../store/utils";
export default {
  components: {
    BreadCrumb,
    TableList,
    FileInput,
    FileProcess,
  },

  data() {
    return {
      // header
      creds: {},
      // files: [[], [], []],
      files: [],
      selectedFileName: "",

      // Case Flow Results
      tableLoading: true,
      tableSearchConfig: {
        title: "Summary Result",
      },
      tableConfig: {
        format: [
          {
            header: "ID",
            width: 5,
          },
          {
            header: "Case File",
            // width: 40,
          },
          {
            header: "Date Created",
            width: 16,
            center: true,
          },
          {
            header: "Created By",
            width: 16,
            center: true,
          },
          {
            header: "Action",
            width: 8,
            center: true,
          },
        ],
        options: [
          {
            icon: "arrow_forward",
            label: "View Campaign",
          },
          {
            icon: "star",
            label: "Bookmark",
          },
          {
            icon: "archive",
            label: "Archive",
          },
          {
            icon: "delete",
            label: "Delete Campaign",
          },
        ],
        pagination: {
          current: 1,
          total: 0,
        },
      },
      fullData: [],
      tableData: [],

      // Dropdown Menu
      selected: null,
    };
  },

  watch: {
    // update route when changing page
    tableConfig: {
      handler(newCf) {
        const page = newCf.pagination.current;
        if (this.$route.query.page == page) return;

        const query = { page };
        if (this.$route.query.file_name)
          query.file_name = this.$route.query.file_name;
        this.$router.push({ query });
      },
      deep: true,
    },

    // update data when changed, works when using backward navigation
    $route() {
      this.loadData();
    },
  },

  methods: {
    // to hide all dropdown menu when clicked on anywhere in the page
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    goBack() {
      this.$router.push({ name: "Legalcasesummarizer" });
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    navigateToCampaign(campaignId) {
      this.$router.push({
        name: "CaseLawSummarizer_Case",
        params: { id: campaignId },
      });
    },
    handleFileInput(event) {
      this.files = event; // Store the file input
    },

    processFiles(event) {
      this.files = event;
    },

    handleFileUpload() {
      if (!this.selectedFileName || this.files.length === 0) {
        this.$toast.error("Please upload a file and provide a name.");
        return;
      }
      
      this.$set(this.files, 2, this.files[1]);
      this.$set(this.files, 0, []);
      this.$set(this.files, 1, []);

      const formData = new FormData();

      if (this.files[2] && this.files[2].length > 0) {
          this.files[2].forEach((file) => {
            formData.append("case_file", file.file);
          });
        }
      formData.append("file_name", this.selectedFileName);
      formData.append("location_id", 1);
      formData.append("language", "en");

      casesummarizer
        .UploadFile(formData)
        .then((response) => {
          if (response.status) {
            this.$toast.success("File successfully uploaded.");
            this.loadData();
            this.hideAttachModal();
            this.selectedFileName = "";
          } else {
            this.$toast.error(response.message || "Error during upload.");
          }
        })
        .catch((error) => {
          console.error("Upload error:", error);
          this.$toast.error("There was an error uploading the file.");
        });
    },

    sortData(data) {
      this.tableData = data;
    },

    tableHandler(o, id) {
      this.selected = this.tableData[id][0];
      const campaignId = this.tableData[id][0];

      switch (o) {
        // View File
        case 0:
          this.viewCampaign(campaignId);
          break;

        // Bookmark
        case 1:
          this.bookmarkCampaign();
          break;

        // Archive
        case 2:
          this.archiveCampaign();
          break;

        // Delete
        case 3:
          this.$bvModal.show("delete-modal");
          break;
      }
    },
    viewCampaign(campaignId) {
      this.$router.push({
        name: "CaseLawSummarizer_Case",
        params: { id: campaignId },
      });
    },
    archiveCampaign() {
      casesummarizer.ArchiveCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },
    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      casesummarizer.DeleteCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },

    bookmarkCampaign() {
      casesummarizer.BookmarkCampaign(this.selected).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },  

    loadData() {
      const file_name = this.$route.query.file_name ?? null;
      const page = this.$route.query.page;

      const obj = { type: "case_summarizer", page };
      if (file_name) obj.file_name = file_name;

      this.tableLoading = true;
      casesummarizer.GetCampaigns(obj).then((res) => {
        if (!res) return;
        const DATA = res.data.data;
        this.tableConfig.pagination = {
          current: DATA.current_page,
          total: DATA.total,
          perPage: DATA.per_page,
        };
        this.fullData = DATA.data;
        this.statLoading = false;
        this.tableLoading = false;

        this.tableData = this.fullData.map((ent) => {
          return [
            ent.id,
            ent.file_name,
            ent.created_at,
            this.$options.filters.username(ent.creator.name),
            ent.completedPerCentage,
          ];
        });
      });
    },
    sendQuery(q) {
      const Rquery = this.$route.query;
      const query = { page: 1 };
      if (q) {
        query.file_name = q;
        this.$router.push({ query });
      } else if (Rquery.page > 1 || Rquery.file_name) {
        this.$router.push({ query });
      }
    },
  },

  created() {
    const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits;
    this.creds.les = CRED.full.les - CRED.used.les;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
};
</script>

<style scoped>
.table-margin {
  margin: 16px 10px 0px 10px;
}

.btn-primary {
  height: 40px;

  span {
    margin-right: 10px;
  }
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }

  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
.black {
  font-weight: 400 !important;
}
.modal-icon {
  cursor: pointer;
}
.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  cursor: pointer;
}
</style>
